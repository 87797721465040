<template>
  <div class="flex items-center justify-center flex-col">
    <div class="max-w-[818px]">
      <img src="@/assets/img/404.svg" class="h-auto max-w-full w-full">
    </div>
    <div class="mb-[15px]">
      <h3 class="text-[22px] md:text-[36px] leading-[1] uppercase text-white font-bold mb-[10px]">Something went wrong</h3>
      <p class="!m-0 text-white tracking-[0.4px] leading-[1] text-[16px] md:text-[21px] font-normal">Error 404. Page not found</p>
    </div>
    <Button
      text="back to home"
      class="btn btn-small btn-bright btn-bright-small !min-w-[276px] !min-h-[46px]"
      @click="backToHome"
      brightBtn
    />
  </div>
</template>

<script>
import Button from '@/components/_common/FormElements/Button/Button';
export default {
  name: "PageNotFound",
  components: {
    Button,
  },
  methods: {
    backToHome() {
      this.$router.push({
        path: '/exchange/dashboard',
      })
    }
  }
};
</script>
